import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, } from '@material-ui/core';
import OurHostImg1 from '../../assets/our-host-1.png';
import OurHostImg2 from '../../assets/our-host-2.png';
import './EventwebinarcontentOurHostsTile.scss';


export default function EventwebinarcontentOurHostsTile() {
    return (
        <>
            <Grid item xl={3} lg={3} md={6} sm={6}></Grid>
            <Grid item xl={3} lg={3} md={6} sm={6}></Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
            <Grid className='tile-spacing'>
              <Card className='our-host-card'>
                <CardMedia component="img" alt="green iguana" image={OurHostImg1} />
                <CardContent>
                  <h4> Gotham Sindleom</h4>
                  <p>People Function, Lead <span>Canada</span></p>
                </CardContent>
              </Card>
              </Grid>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
            <Grid className='tile-spacing'>
              <Card className='our-host-card'>
                <CardMedia component="img" alt="green iguana" image={OurHostImg2} />
                <CardContent>
                  <h4>Margaret Elicia</h4>
                  <p>People Function, Lead<span>Adelaide</span></p>
                </CardContent>
              </Card>
            </Grid>
            </Grid>
        </>
    )
}