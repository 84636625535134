import React from 'react'
import { Grid } from "@mui/material";
import EventwebinarcontentPastEventsTile from '../../../staticcomponents/EventwebinarcontentPastEventsTile';
import './EventsWebinarPastEvents.scss';

export default function EventsWebinarPastEvents() {
    return (
        <>
            <Grid container className="event-webinar-past-event-cls">
                {/* Title Starts */}
        <Grid item xl={1} lg={1} md={1} sm={0} xs={0}></Grid>
        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
            <Grid container spacing={4} className='past-events-container'>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                   <h2 className='event-title title-section'>past events</h2>
                </Grid>
                <EventwebinarcontentPastEventsTile />
            </Grid>
        </Grid>
        <Grid item xl={1} lg={1} md={1} sm={0} xs={0}></Grid>
            </Grid>
        </>
    )
}


