import React from 'react'
import './EventsWebinarHeader.scss'
import { Grid, Button } from "@mui/material";
import rsrch_cnt_hero_image from '../../../assets/researchContentBg.png';
import supply_chain from '../../../assets/career-mobility-growth-2.png';

export default function EventsWebinarHeader() {
  return (
    <>
      <Grid container className="eventswebinar-content-header-cls">
        {/* **********************    Text-with-Image-row    ********************** */}

        <Grid item xl={1} lg={1} md={2} sm={0} xs={0}></Grid>

        <Grid item xl={11} lg={11} md={10} sm={12} xs={12}>
          <Grid container className="webinar-header-container">
            <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
              <div className="header-title-sec">
                <p className="header-date">
                  EVENTS / 22 JUN 2022
                </p>
                <h1>
                  cartus mobility insights webinar series APAC
                </h1>
              </div>
            </Grid>

            <Grid item xl={7} lg={7} md={12} sm={9} xs={12}>
              <div className="webinar-header-heroimg">
                <img src={rsrch_cnt_hero_image} alt="eventwebinar Content Heroimg" />
              </div>
            </Grid>
          </Grid>

        </Grid>

        {/* **********************   Text-row-2    ********************** */}

        <Grid container>
          <Grid item xl={1} lg={1} md={2} sm={0} xs={0}></Grid>
          <Grid item xl={4} lg={4} md={10} sm={10} xs={12}>
            <div className='eventswebinar-content-rich-txt'>
              <p>Date: <b>19 January 2022, Wednesday</b> </p>
              <p>Time:<b> 3 – 4pm SGT (Singapore Time)</b> </p>
              <p>Type of Event:<b> Microsoft Teams event</b> </p>
              <p>Audience: <b>Global mobility and HR professionals</b></p>
              <Button variant="contained" className='blue-btn'>download</Button>
            </div>
          </Grid>
          <Grid container item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid item xl={2} lg={2} md={3} sm={2} xs={0}></Grid>
            <Grid item xl={10} lg={10} md={9} sm={10} xs={12}>
              <div className='webinar-des'>
                <h3 className='title'>event details:</h3>
                <p>
                  As companies gear up for 2022, trending phrases such as “Remote Work”, “Flexibility” and “Customer Experience (CX)” seem to be in the forefront of organisations’ planning—an indication of how the environment in which we operate has changed significantly and is still evolving.
                </p>
                <p>
                  Companies are fighting the talent war while navigating the delicate balance between employee needs and company objectives. In this webinar, we will explore what is the best way forward for global mobility in the current landscape and how we can make a difference in employees’ relocation journey.
                </p>
              </div>
            </Grid>

          </Grid>
          <Grid item xl={1} lg={1} md={0} sm={0} xs={0}></Grid>
        </Grid>

        {/* **********************   Text-row-3    ********************** */}
        <Grid container className='webinar-img-txt-container'>
          <Grid item xl={5} lg={5} md={7} sm={9} xs={11}>
            <div className='img-thumb-div'>
              <img className="img-thumb" src={supply_chain} alt="Supply Chain" />
            </div>
          </Grid>

          <Grid container item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Grid item xl={2} lg={2} md={3} sm={2} xs={0}></Grid>
            <Grid item xl={10} lg={10} md={9} sm={10} xs={12}>
              <div className='webinar-des'>
                <h3 className='title'>key learnings</h3>
                <p>
                  Vaccination programs are being rolled out, borders are re-opening, and organizations are ramping up their mobility programs (albeit cautiously). As we leave the “new normal”, this webinar explores what the next phase in this global pandemic is beginning to look like for global mobility, including:
                </p>
                <p>
                  Determining whether it really is business as usual when it comes to relocating employees The tools and strategies needed to meet today’s global mobility challenges Real-life case studies and examples of how Mastercard, Textron, and Westinghouse are achieving their mobility objectives.

                </p>
              </div>
            </Grid>

          </Grid>
          <Grid item xl={1} lg={1} md={0} sm={0} xs={0}></Grid>
        </Grid>

      </Grid>
    </>
  )
}


