import React from 'react';
import { Grid } from "@mui/material";
import PastEventsimg1 from '../../assets/past-events-img-1.png';
import PastEventsimg2 from '../../assets/past-events-img-2.png';
import PastEventsimg3 from '../../assets/past-events-img-3.png';
import { CardMedia, Card, CardContent } from '@material-ui/core';
import './EventwebinarcontentPastEventsTile.scss';


export default function EventwebinarcontentPastEventsTile() {
    return (
        <>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <Card className="past-events-card">
                    <CardMedia component="img" alt="green iguana" image={PastEventsimg1} />
                    <CardContent className="card-content">
                        <Grid item container spacing={2}>
                            <Grid item direction="column">
                                <div className="past-events-title">
                                    cartus global mobility insights: optimising employee relocation
                                </div>
                                <div className="past-events-date">
                                    JUL 2022
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <Card className="past-events-card">
                    <CardMedia component="img" alt="green iguana" image={PastEventsimg2} />
                    <CardContent className="card-content">
                        <Grid item container spacing={2}>
                            <Grid item direction="column">
                                <div className="past-events-title">
                                    cartus global insights apac webinar: mobility mobilisation
                                </div>
                                <div className="past-events-date">
                                    JUL 2022
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <Card className="past-events-card">
                    <CardMedia component="img" alt="green iguana" image={PastEventsimg3} />
                    <CardContent className="card-content">
                        <Grid item container spacing={2}>
                            <Grid item direction="column">
                                <div className="past-events-title">
                                    a guide to global talent mobility in a post-covid-19 world
                                </div>
                                <div className="past-events-date">
                                    JUL 2022
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}