import React from 'react'
import './EventsWebinarPresenters.scss'
import { Grid,Container } from '@material-ui/core';
import EventwebinarcontentPresentersTile from '../../../staticcomponents/EventwebinarcontentPresentersTile';
import EventwebinarcontentOurHostsTile from '../../../staticcomponents/EventwebinarcontentOurHostsTile';
export default function EventsWebinarPresenters() {
  return (
    <>
    {/* presenters component start */}
    <Container maxWidth="xl" className='events-content-container-div'>
      <Grid container className='events-webinar-presenters-cls'>
        {/* **********************    Text-with-Image-row    ********************** */}
        <Grid item xl={1} lg={1} md={1} sm={0} xs={0}></Grid>

        <Grid item xl={10} lg={10} md={7} sm={12} xs={12}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <h2 className='presenters-title title-section'>presenters </h2>
            </Grid>
            <EventwebinarcontentPresentersTile />
          </Grid>
        </Grid>
        <Grid item xl={1} lg={1} md={4} sm={12} xs={12}></Grid>

      </Grid>
      </Container>
    {/* Our host component start   */}
    <div className="capsules-bg-left">
    <Container maxWidth="xl" className='events-content-container-div'>
    <Grid container className='events-webinar-host-cls'>
        {/* **********************    Text-with-Image-row    ********************** */}
        <Grid item xl={1} lg={1} md={1} sm={0} xs={0}></Grid>

        <Grid item xl={10} lg={10} md={7} sm={12} xs={12}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <h2 className='our-host-title title-section'>our hosts </h2>
            </Grid>
            <EventwebinarcontentOurHostsTile />
          </Grid>
        </Grid>
        <Grid item xl={1} lg={1} md={4} sm={12} xs={12}></Grid>

      </Grid>
      </Container>
      </div>
    </>
  )
}


