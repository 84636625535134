import React from 'react'
import Layout from '../../staticcomponents/layout'
import './eventswebinarcontent.scss'
import EventsWebinarHeader from './eventswebinarheader'
import EventsWebinarPresenters from './presenters'
import EventsWebinarPastEvents from './pastevents'
import CategorySocialMedia from '../../staticcomponents/category-social media'
import Container from '@material-ui/core/Container';

export default function EventsWebinarContent() {
    return (
        <>
            <Layout>
                <div className='events-webinarcontent-main'>
                    <div className='bg-donuts-eventswebinar'>
                    
                    <Container maxWidth="xl" className='events-content-container-div'>
                        <EventsWebinarHeader />
                        </Container> 
                        <EventsWebinarPresenters/>
                        <Container maxWidth="xl" className='events-content-container-div'>
                        <EventsWebinarPastEvents/>
                        <CategorySocialMedia/>
                    </Container> 
                    
                    </div>
                </div>
            </Layout>
        </>
    )
}