import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, } from '@material-ui/core';
import PresentersImg1 from '../../assets/presenters-1.png';
import PresentersImg2 from '../../assets/presenters-2.png';
import PresentersImg3 from '../../assets/presenters-3.png';
import PresentersImg4 from '../../assets/presenters-4.png';
import PresentersImg5 from '../../assets/presenters-5.png';
import PresentersImg6 from '../../assets/presenters-6.png';
import './EventwebinarcontentPresentersTile.scss';


export default function EventwebinarcontentPresentersTile() {
    return (
        <>
             <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
              <Grid className='tile-spacing'>
              <Card className='presenters-card'>
                <CardMedia component="img" alt="green iguana" image={PresentersImg1} />
                <CardContent>
                  <h4>Marlie Winchelot</h4>
                  <p>People Function, Lead <span>Global Mobility</span></p>
                </CardContent>
              </Card>
              </Grid>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
            <Grid className='tile-spacing'>
              <Card className='presenters-card'>
                <CardMedia component="img" alt="green iguana" image={PresentersImg2} />
                <CardContent>
                  <h4>Colin Phaseus </h4>
                  <p>People Function, Lead <span>Global Mobility</span></p>
                </CardContent>
              </Card>
              </Grid>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}> </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
            <Grid className='tile-spacing'>
              <Card className='presenters-card'>
                <CardMedia component="img" alt="green iguana" image={PresentersImg3} />
                <CardContent>
                  <h4>Carla Mendleway jackson</h4>
                  <p>People Function, Lead <span>Global Mobility</span></p>
                </CardContent>
              </Card>
              </Grid>
            </Grid>

             {/* 2nd card box start */}
             <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
             <Grid className='tile-spacing'>
              <Card className='presenters-card'>
                <CardMedia component="img" alt="green iguana" image={PresentersImg4} />
                <CardContent>
                  <h4>Gotham Sindleom </h4>
                  <p>People Function, Lead <span>Global Mobility</span></p>
                </CardContent>
              </Card>
              </Grid>
            </Grid>

            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}></Grid>

            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
            <Grid className='tile-spacing'>
              <Card className='presenters-card'>
                <CardMedia component="img" alt="green iguana" image={PresentersImg5} />
                <CardContent>
                  <h4>Carla Mendleway jackson</h4>
                  <p>People Function, Lead <span>Global Mobility</span></p>
                </CardContent>
              </Card>
              </Grid>
            </Grid>

            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
            <Grid className='tile-spacing'>
              <Card className='presenters-card'>
                <CardMedia component="img" alt="green iguana" image={PresentersImg6} />
                <CardContent>
                  <h4>Kylie Densabu</h4>
                  <p>People Function, Lead <span>Global Mobility</span></p>
                </CardContent>
              </Card>
              </Grid>
            </Grid>
        </>
    )
}